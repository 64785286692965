.voteActions {
  display: flex;
  align-items: center;
  pointer-events: auto;
}

.voteButtons {
  display: flex;
  flex-direction: column;
}

.voteButton {
  align-items: center;
  background-color: unset;
  border: none;
  padding: 1px;
}

.voteCount {
  margin: 0 2px;
}
