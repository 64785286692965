.clip {
  color: var(--colorFG);
  border: none;
  display: flex;
  flex-direction: column;
  margin: 0.5rem auto 2.5rem;
  max-width: var(--maxBodyWidth);
  padding: 0 1.5rem 0 0;
}

.premiumFlag {
  background: var(--colorGradient);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

.premiumFlag:before {
  background: var(--color3);
  content: '';
  display: block;
  height: 1.5rem;
  margin: 0.5rem;
  mask-image: url(../../../public/lock.svg);
  mask-size: 100% 100%;
  width: 2rem;
}

.imageHolder {
  padding-top: 56%;
  position: relative;
  z-index: 99;
}

.image img {
  bottom: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.meta {
  background: var(--colorBGA);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: -2rem -1.5rem 0 1.5rem;
  padding: 0.75rem 1rem 1rem;
  position: relative;
  z-index: 100;
  box-shadow: var(--subtleBoxShadow);
}

.title {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  font-family: var(--fontFamilyHeading);
  font-size: 1.5rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.excerpt {
  display: none;
}

.author,
.date {
  font-family: var(--fontFamilySansSerif);
  font-size: 0.8rem;
}

.author:before {
  content: ',';
  padding-right: 0.2rem;
}

.author:first-child:before {
  content: 'By';
  padding-right: 0.2rem;
}

.minimal {
  opacity: 0.3;
}

.featured {
  margin: 1rem auto 1.5rem;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  padding: 0;
  position: relative;
}

.featured .imageHolder {
  padding-top: 65%;
}

.featured .meta {
  align-items: flex-end;
  background: linear-gradient(
    -25deg,
    hsla(0, 0%, 0%, 0.75),
    rgba(35, 28, 52, 0)
  );
  bottom: 0;
  box-shadow: none;
  color: var(--colorBG);
  height: 100%;
  justify-content: flex-end;
  margin: 0;
  position: absolute;
  width: 100%;
}

.featured .title {
  text-align: right;
}

.featured:nth-child(even) .meta {
  align-items: flex-start;
  background: linear-gradient(
    25deg,
    hsla(0, 0%, 0%, 0.75),
    rgba(35, 28, 52, 0)
  );
}

.featured:nth-child(even) .title {
  text-align: left;
}

@media (min-width: 768px) {
  .list.clip {
    border-radius: 0.3rem;
    flex-direction: row;
    padding: 0;
    box-shadow: var(--subtleBoxShadow);
  }

  .list .imageHolder {
    flex: 0 0 40%;
    padding-top: 0;
  }

  .list .image {
    display: block;
    padding-top: 75%;
  }

  .list .meta {
    box-shadow: none;
    padding: 2rem 2rem 2rem 3rem;
    margin: 0;
  }

  .list .title {
    font-size: 2rem;
  }

  .list .excerpt {
    display: block;
    font-family: var(--fontFamilySansSerif);
    font-size: 0.9rem;
    opacity: 0.7;
    padding: 1rem 0;
  }

  .featured .imageHolder {
    padding-top: 45%;
  }

  .featured .title {
    font-size: 2.25rem;
    margin-left: 10%;
  }

  .featured .excerpt {
    display: block;
    font-family: var(--fontFamilySansSerif);
    font-size: 0.9rem;
    margin: 0.5rem 0 0.5rem 20%;
    opacity: 0.7;
    text-align: right;
  }

  .featured:nth-child(even) .title {
    margin-left: 0;
    margin-right: 10%;
  }

  .featured:nth-child(even) .excerpt {
    margin-left: 0;
    margin-right: 20%;
    text-align: left;
  }
}

@media (min-width: 1025px) {
  .featured .imageHolder {
    padding-top: 37%;
  }

  .featured .title {
    font-size: 2.75rem;
  }

  .featured .meta {
    padding: 1.5rem;
  }

  .featured .excerpt {
    margin: 0.5rem 0 0.5rem 40%;
  }

  .featured:nth-child(even) .excerpt {
    margin-right: 40%;
  }
}
