.container {
  padding: 1rem 0.75rem 1rem 0.75rem;
  position: relative;
  pointer-events: auto;
}

.timeStamp {
  font-size: 0.8rem;
  font-family: var(--fontFamilySansSerif);
}

.toplineContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.toplineInfo {
  display: flex;
  flex-direction: column;
}

.topic {
  display: inline-block;
  color: var(--color1);
  margin-bottom: 0.5rem;
  margin-top: -0.3rem;
  margin-left: -0.3rem;
  text-transform: uppercase;
  pointer-events: auto;
}

.topicLink {
  border: none;
  padding: 0;
}

.author {
  font-weight: 600;
}

.titleContainer {
  font-family: var(--fontFamilyHeader);
  font-weight: 600;
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .container {
    padding: var(--CommPostPadding);
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
    position: relative;
  }

  .timeStamp {
    font-size: 0.8rem;
    font-family: var(--fontFamilySansSerif);
  }

  .toplineContainer {
    display: flex;
    align-items: baseline;
    gap: 3rem;
  }

  .titleContainer {
    font-family: var(--fontFamilyHeader);
    font-weight: 600;
    font-size: 1.75rem;
  }

  .topic {
    display: inline-block;
    color: var(--color1);
    margin-bottom: 1rem;
    margin-top: -1rem;
    margin-left: -1rem;
    text-transform: uppercase;
  }

  .author {
    font-weight: 600;
  }
}
