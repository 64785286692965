.loader {
  color: var(--color1);
  font-family: var(--fontFamilySansSerif);
  font-size: 16.5vw;
  letter-spacing: -0.1rem;
  line-height: 0.9;
  margin: 7rem auto 6rem;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  text-align: center;
  text-transform: uppercase;
}
.smallLoader {
  color: var(--color1);
  font-family: var(--fontFamilySansSerif);
  font-size: 4rem;
  letter-spacing: -0.1rem;
  line-height: 0.9;
  margin: 0 auto 2rem;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  text-align: center;
  text-transform: uppercase;
}

.full {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  min-height: calc(100vh - 100px);
  padding-top: 0;
}

@media (min-width: 768px) {
  .full {
    min-height: calc(100vh - 0px);
  }
}

@media (min-width: 1025px) {
  .heading {
    font-size: 11.54rem;
  }
}
