.commentBodyContainer {
  padding: 1rem 0.75rem 0.5rem 0.75rem;
  padding-bottom: 0rem;
  max-height: 250px;
  overflow: hidden;
  position: relative;
  pointer-events: auto;
}

.commentBodyFader {
  position: absolute;
  pointer-events: auto;
  height: 20%;
  width: 100%;
  bottom: 0;
  background: linear-gradient(
    hsla(210, 100%, 99%, 0),
    hsla(210, 100%, 99%, 0.9) 70%
  );
}

.bodyBottom {
  height: 1rem;
  padding: 1rem 0.75rem 0.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: auto;
}

.revealText {
  font-size: 0.75rem;
  pointer-events: auto;
  color: var(--color1);
}

.readMore {
  font-size: 0.75rem;
  color: var(--color1);
}

.voteActions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.voteButtons {
  display: flex;
  flex-direction: column;
}

.voteButton {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}

@media (min-width: 768px) {
  .commentBodyContainer {
    padding: var(--CommPostPadding);
    margin-bottom: 2rem;
    max-height: 300px;
    overflow-y: hidden;
    position: relative;
  }

  .commentBodyFader {
    position: absolute;
    height: 40%;
    width: 95%;
    bottom: 0;
    left: 10px;

    background: linear-gradient(
      hsla(210, 100%, 99%, 0),
      hsla(210, 100%, 99%, 0.9) 70%
    );
  }

  .mobileVotesContainer {
    position: absolute;
    top: 2%;
    left: -6%;
  }

  .bodyBottom {
    height: 0.5rem;
    padding: 0.5rem 1.5rem;
    padding-left: 2.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
