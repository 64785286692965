.caption {
  color: var(--color3);
  display: block;
  font-size: 0.6rem;
  letter-spacing: 0.1rem;
}

.heading {
  color: var(--color1);
  font-family: var(--fontFamilySansSerif);
  font-size: 16.5vw;
  letter-spacing: -0.1rem;
  line-height: 0.9;
  margin: 0 auto -0.35em;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
}

.title {
  flex: 1;
  text-align: left;
}

.minimal {
  align-items: flex-end;
  border: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;
  font-weight: bold;
  justify-content: space-between;
  letter-spacing: 0.05rem;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
}

a.minimal:after {
  color: var(--color1);
  content: '>';
  order: 2;
  padding-bottom: 0.5rem;
}

.minimal .title {
  font-size: 2.5rem;
  padding: 0 var(--pageGutter) 0.75rem 0;
  order: 1;
}

a.minimal:after,
.minimal .title {
  border-bottom: 1px solid var(--color1);
}

.minimal .caption {
  flex: 0 0 100%;
  font-size: 0.6rem;
  line-height: 1.4;
  margin-bottom: 0;
  order: 3;
  padding: 0.5rem 0;
}

@media (min-width: 768px) {
  .heading {
    margin: 0 auto 3rem;
  }

  .minimal {
    margin-bottom: 1rem;
  }

  .caption {
    font-size: 0.9rem;
    margin-bottom: -0.75em;
  }
}

@media (min-width: 1024px) {
  .heading {
    font-size: 11rem;
  }

  .minimal {
    font-size: 2rem;
  }

  .minimal .title {
    font-size: 3rem;
  }

  .inline {
    align-self: center;
    flex: calc(50% - 1.5rem);
    font-size: 6rem;
    letter-spacing: 0.2rem;
    margin: 0 0.75rem 0 0;
  }
}
