.hero {
  background: var(--colorShadow);
  margin-bottom: 2rem;
  max-height: 1000px;
  min-height: 500px;
  padding-top: 70vh;
  position: relative;
}

.hero:after {
  background: linear-gradient(
    30deg,
    var(--colorBG) 10%,
    rgba(21, 17, 31, 0) 100%
  );
  content: '';
  left: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.hero:before {
  background: linear-gradient(0, var(--colorBG) 0, rgba(21, 17, 31, 0) 100%);
  bottom: 0;
  content: '';
  left: 0;
  height: 20%;
  position: absolute;
  right: 0;
  z-index: var(--zIndexLevelOne);
}

.heroContents {
  align-items: center;
  bottom: 0;
  color: var(--colorFG);
  display: flex;
  flex-direction: column;
  font-family: var(--fontFamilyHeading);
  font-size: 2.5rem;
  line-height: 1;
  justify-content: center;
  left: 1.5rem;
  position: absolute;
  right: 1.5rem;
  text-align: center;
  top: var(--headerHeight);
  z-index: var(--zIndexLevelOne);
}

.heroContents img {
  border-radius: 5%;
  height: 125px;
  margin-bottom: 1.5rem;
  margin-top: -2rem;
  width: 125px;
}

.title {
  font-size: 2.75rem;
  line-height: 1.1;
  margin: 0 0 1rem;
}

.title span {
  color: var(--color3);
}

.signup .title {
  font-size: 2rem;
}

.deck {
  font-family: var(--fontFamilySerif);
  font-size: 0.95rem;
  line-height: 1.5;
  max-width: var(--maxReadWidth);
}

.heroImg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.heroImg img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.social {
  font-size: 1rem;
  margin: 1.5rem 0 0;
}

.social a {
  margin: 0 1rem;
}

.form {
  margin: 0 auto;
  max-width: 47rem;
}

.emailTitle {
  font-size: 2.3rem;
  line-height: 1.1;
  margin: 0 auto 1.5rem;
  position: relative;
}

.subTitle {
  font-family: var(--fontFamilySerif);
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0 auto 2rem;
  padding-bottom: 1.5rem;
  position: relative;
}

.subTitle:after {
  background: var(--color3);
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 60px;
}

.cta {
  background: var(--colorFG);
  border-radius: 1.5rem;
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: auto;
}

.cta input {
  flex: 1;
}

.terms {
  font-family: var(--fontFamilySansSerif);
  font-size: 0.7rem;
  opacity: 0.6;
  margin-top: 1.5rem;
}

.link {
  margin: 0 0.1rem;
}

.followButton {
  margin-top: 0.5rem;
}

.checkbox {
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  .hero {
    padding-top: 50vh;
  }

  .heroContents img {
    height: 150px;
    width: 150px;
  }

  .title {
    bottom: 3rem;
    font-size: 3.5rem;
    left: 3rem;
    max-width: 70%;
  }

  .deck {
    font-size: 1rem;
  }

  .signup .title {
    font-size: 3rem;
  }

  .emailTitle {
    font-size: 3rem;
  }

  .subTitle {
    font-size: 1rem;
  }
}

@media (min-width: 1025px) {
  .hero {
    padding-top: 65vh;
  }

  .heroContents {
    font-size: 3rem;
  }

  .title {
    font-size: 3.5rem;
  }
}
