.footerWrapper {
  display: flex;
  background-color: var(--colorBGA);
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding: 1rem 0.75rem 1rem 0.75rem;
  pointer-events: none;
}

.authorUsername {
  font-weight: bold;
  pointer-events: auto;
}

.authorContainer {
  display: flex;
  align-items: center;
}

.replyEditorContainer {
  animation: expand 0.3s;
  width: 85%;
  background: var(--colorBG);
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  box-shadow: 3px 3px 15px hsla(0, 0%, 5%, 0.6);
}

.replyContainer {
  position: absolute;
  width: 100%;
  left: 0;
  top: 15%;
}

.replyButton {
  border: none !important;
  background: transparent !important;
  padding: 0;
  position: relative;
}

.replyButton:hover {
  opacity: 0.6;
}

.replyIndicator {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--color3);
  box-shadow: 0px 0px 11px var(--color3);
}

.replySection {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color1);
}

.viewReplyThread {
  display: flex;
}

.viewThreadText {
  border: none;
  text-decoration: none;
}

.nullContainer {
  margin-bottom: 2rem;
}
.pointer {
  stroke: '#dd745a';
}

.authorInfo {
  position: relative;
}

.avatar {
  position: absolute;
  left: -0.3rem;
  bottom: -0.6rem;
  border: none;
}

.authorLink {
  padding: 2rem 0rem;
  border: none;
  margin-left: 2.5rem;
}

.commentCountContainer {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.commentCountLink {
  border: none;
  pointer-events: auto;
}

.footerRight {
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media (min-width: 768px) {
  .footerWrapper {
    display: flex;
    background-color: var(--colorBGA);
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.5rem 1rem 0.5rem;
  }

  .mobileVoteContainer {
    display: none;
  }

  .replyEditorContainer {
    animation: expand 0.3s;
    background: var(--colorBG);
    border-radius: 5px;
    padding: 0.5rem 1rem 2rem 1rem;
    box-shadow: 3px 3px 15px hsla(0, 0%, 5%, 0.6);
  }

  .replyContainer {
    position: absolute;
    width: 100%;
    left: 0;
    top: 15%;
  }

  .replyButton {
    border: none !important;
    background: transparent !important;
  }

  .replyButton:hover {
    opacity: 0.6;
  }

  .authorLink {
    padding: 2rem;
  }

  .viewReplyThread {
    display: flex;
  }

  .nullContainer {
    margin-bottom: 2rem;
  }

  .authorInfo {
    position: relative;
  }

  .avatar {
    position: absolute;
    left: 0.5rem;
    bottom: -0.6rem;
    border: none;
  }

  .authorLink {
    padding: 2rem 0rem;
    border: none;
    margin-left: 3.75rem;
  }
}

@keyframes expand {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(100%);
  }
}
