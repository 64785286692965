.wrapper {
  max-width: var(--maxBodyWidth);
  max-height: 550px;
  margin: 0 auto;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  box-shadow: var(--subtleBoxShadow);
  position: relative;
  background: var(--colorBG);
  position: relative;
}

.fullWidthVoteContainer {
  display: none;
}

.commPostLink {
  pointer-events: none;
}

@media (min-width: 768px) {
  .wrapper {
    max-height: unset;
    max-width: var(--maxBodyWidth);
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    box-shadow: var(--subtleBoxShadow);
  }

  .fullWidthVoteContainer {
    display: unset;
    position: absolute;
    left: -5%;
  }
}
