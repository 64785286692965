.headerLargeNegativeMargin,
.headerSmallNegativeMargin {
  margin-bottom: 0.8rem;
}

@media (min-width: 768px) {
  .headerLargeNegativeMargin {
    margin-bottom: -4.5rem;
  }

  .headerSmallNegativeMargin {
    margin-bottom: 0.5rem;
  }
}
